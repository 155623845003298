import axios from 'axios'
import { Store } from 'react-notifications-component'

// axios instance for making requests
const axiosInstance = axios.create()

// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
  const token = localStorage.getItem('token')
  if (token) {
    config.headers['Authorization'] = 'Bearer ' + token
  }
  return config
})

axiosInstance.interceptors.response.use(
  (response) => {
    console.log({ response })
    if (response?.data?.status === 401 || response?.data?.status === 403) {
      localStorage.clear()
    }
    if (response?.data?.status >= 400) {
      Store.addNotification({
        title: 'Error',
        message: response?.data?.message || 'Unexpected Error',
        type: 'danger',
        insert: 'top',
        container: 'top-right',
        animationIn: ['animate__animated', 'animate__fadeIn'],
        animationOut: ['animate__animated', 'animate__fadeOut'],
        dismiss: {
          duration: 2000,
          onScreen: true,
        },
      })
    }

    return response
  },
  function (error) {
    return Promise.reject(error)
  },
)

export default axiosInstance
