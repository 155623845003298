function Input({ label, placeholder, value, setValue }) {
  return (
    <div className="input-group input-group-outline">
      <label
        htmlFor="fName"
        className="my-3 block text-base font-medium text-white text-left"
      >
        {label}
      </label>
      <input
        type="text"
        name="fName"
        id="fName"
        value={value}
        onChange={(e) => setValue(e?.target?.value || '')}
        placeholder={placeholder}
        className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
      />
    </div>
  )
}

export default Input
