import { GoogleLogin } from '@moeindana/google-oauth'
import { googleLogout } from '@moeindana/google-oauth'
import Logout from './logout'
import axios from 'axios'
import API_CONSTANT from '../../config/Apiconstant'
import utils from '../../config/util'

function NavBar({ isLogin, setIsLogin }) {
  const login = async (response) => {
    const { data: axiosResponse } = await axios.post(
      API_CONSTANT.API_USER_LOGIN,
      {
        Email: response.email,
        Username: response.name,
        GoogleAuth: response.credential,
        ProfileIcon: response.picture,
      },
    )
    localStorage.setItem('token', axiosResponse?.data?.token)
    setIsLogin(utils.isUserLogin())
  }

  const logout = () => {
    localStorage.clear()
    googleLogout()
    setIsLogin(utils.isUserLogin())
  }

  return (
    <div className="block px-4 py-4">
      {isLogin && (
        <GoogleLogin
          onSuccess={login}
          onError={() => {
            console.log('Login Failed')
          }}
        />
      )}
      {!isLogin && <Logout onClick={logout} />}
    </div>
  )
}

export default NavBar
