import React, { useEffect, useState, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import API_CONSTANT from './config/Apiconstant'
import axios from 'axios'

function SharePage(props) {
    const { id } = useParams()
    const [linkData, setLinkData] = useState(null)
    const [copyStats1, setCopyStats1] = useState(false)
    const [copyStats2, setCopyStats2] = useState(false)
    const [loading, setLoading] = useState(null)

    const getLinksData = useCallback(async () => {
        setLoading(true)
        const { data: axiosResponse } = await axios.get(
            API_CONSTANT.API_GET_SINGLE_LINK + id,
        )
        setLinkData(axiosResponse?.data?.data)
        setLoading(false)
        const seenPage = sessionStorage.getItem('seen')
        if (seenPage) return
        if (navigator.userAgent.match(/Android/i)) {
            sessionStorage.setItem('seen', 'true')
            window.location.assign(axiosResponse?.data?.data?.androidLink)
        } else if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
            sessionStorage.setItem('seen', 'true')
            window.location.assign(axiosResponse?.data?.data?.iosLink)
        } else {
            console.log('The user is using some other device.')
        }
    }, [id])

    useEffect(() => {
        getLinksData()
    }, [getLinksData])

    if (loading)
        return (
            <div className="flex justify-center items-center">
                <div
                    className="spinner-border animate-spin inline-block w-8 h-8 border-4 rounded-full"
                    role="status"
                >
                    <span className="visually-hidden"></span>
                </div>
            </div>
        )
    if (!linkData) return <div>Not Found 404!</div>
    return (
        <div className="flex bg-slate-900 items-center justify-center h-screen">
            <div className="md:w-1/3 w-11/12 bg-white rounded shadow-lg p-4">
                <div className="flex flex-col items-center justify-between my-4">
                    <h1 className='font-extrabold'>Please choose the store link</h1>
                    <div className='w-screen md:w-3/4 overflow-hidden flex py-2 justify-center items-center gap-2'>
                        <p className='w-3/4 relative z-10 px-4 py-2 overflow-x-scroll whitespace-nowrap hover:bg-gray-200 hover:shadow'>{linkData.androidLink}</p>
                        <img
                            src={!copyStats1 ? "/copy.png" : '/done.png'}
                            alt="copy to clipboard button"
                            height={20}
                            width={20}
                            className="cursor-pointer"
                            onClick={() => {
                                setCopyStats1(true)
                                navigator.clipboard.writeText(
                                    linkData.androidLink
                                )
                            }}
                        ></img>
                    </div>
                    <button
                        onClick={() => window.open(linkData.androidLink, '_blank')}
                        className="px-4 py-2 bg-green-500 rounded-lg text-white hover:bg-green-600 md:w-40 w-32"
                    >
                        Android
                    </button>
                    <div className='w-screen md:w-3/4 overflow-hidden flex py-2 justify-center items-center gap-2'>
                        <p className='w-3/4 relative z-10 px-4 py-2 overflow-x-scroll whitespace-nowrap hover:bg-gray-200 hover:shadow'>{linkData.iosLink}</p>
                        <img
                            src={!copyStats2 ? "/copy.png" : '/done.png'}
                            alt="copy to clipboard button"
                            height={20}
                            width={20}
                            className="cursor-pointer"
                            onClick={() => {
                                setCopyStats2(true)
                                navigator.clipboard.writeText(
                                    linkData.iosLink
                                )
                            }}
                        ></img>
                    </div>
                    <button
                        onClick={() => window.open(linkData.iosLink, '_blank')}
                        className="px-4 py-2 bg-blue-500 rounded-lg text-white hover:bg-blue-600 md:w-40 w-32"
                    >
                        iOS
                    </button>
                </div>
            </div>
        </div>
    )
}

export default SharePage
