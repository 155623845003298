import { GoogleOAuthProvider } from '@moeindana/google-oauth'
import './App.css'
import Button from './components/button/button'
import Input from './components/Input/input'
import NavBar from './components/navbar/navbar'
import Table from './components/table/table'
import utils from './config/util'
import { useState, useEffect, useCallback } from 'react'
import API_CONSTANT from './config/Apiconstant'
import axiosInstance from './config/axios_custom'
import { Store } from 'react-notifications-component'

function HomePage() {
    const [isLogin, setIsLogin] = useState(() => utils.isUserLogin())
    const [androidLink, setAndroidLink] = useState('')
    const [iosLink, setIosLink] = useState('')
    const [linkList, setLinksList] = useState([])

    const getLinksData = useCallback(async () => {
        if (isLogin) return
        const { data: axiosResponse } = await axiosInstance.get(
            API_CONSTANT.API_GET_LINKS,
        )
        const linksData = (axiosResponse?.data?.data || []).map((item) => {
            return {
                ...item,
                copied: false
            }
        })
        setLinksList(linksData)
    }, [isLogin])

    const createNewLink = async () => {
        const { data: axiosResponse } = await axiosInstance.post(
            API_CONSTANT.API_CREATE_LINK,
            {
                AndroidLink: androidLink,
                IosLink: iosLink,
            },
        )
        if (axiosResponse.status !== 201) return
        getLinksData()
        setAndroidLink('')
        setIosLink('')
        Store.addNotification({
            title: 'Done',
            message: 'Created New Link',
            type: 'success',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        })
    }

    const isLinkCreateAllowed = () => {
        if (isLogin || !androidLink || !iosLink) return true
        if (
            !/^https:\/\/play.google.com\/store\/apps\/details\?id=.+$/g.test(
                androidLink,
            )
        )
            return true
        if (!/^https:\/\/apps.apple.com\/.+\/app\/.+$/g.test(iosLink)) return true
        return false
    }

    useEffect(() => {
        getLinksData()
        return () => {
            setLinksList([])
        }
    }, [isLogin, getLinksData])

    return (
        <GoogleOAuthProvider clientId="369129381084-4oksr5fvvf7qmc3i38dah4l2h4j2vqr6.apps.googleusercontent.com">
            <div className="App min-h-screen bg-slate-900 flex  flex-col">
                <div className="flex-row flex justify-between">
                    <div className="flex items-center text-white pl-5 text-lg">
                        ONE LINK
                    </div>
                    <NavBar isLogin={isLogin} setIsLogin={setIsLogin} />
                </div>
                <div className="flex-1 justify-center flex mt-28">
                    <div className="md:w-1/2 w-11/12">
                        <Input
                            label={'Android Store Link'}
                            placeholder={'https://play.google.com/store/apps/'}
                            value={androidLink}
                            setValue={setAndroidLink}
                        />
                        <Input
                            label={'IOS Store Link'}
                            placeholder={'https://apps.apple.com/{country}/app/'}
                            value={iosLink}
                            setValue={setIosLink}
                        />
                        {isLogin && (
                            <p className="text-red-600 mt-2">Please Login to create links!</p>
                        )}
                        <br />
                        <Button
                            label={'Create'}
                            isDisabled={isLinkCreateAllowed}
                            onClick={createNewLink}
                        />
                    </div>
                </div>
                <div className="flex-1 justify-center flex">
                    <Table linkList={linkList} getLinksData={getLinksData} setLinksList={setLinksList} />
                </div>
            </div>
        </GoogleOAuthProvider>
    )
}

export default HomePage
