function Logout({ onClick }) {
  return (
    <button
      type="button"
      onClick={onClick}
      className="inline-block px-6 py-2 border-2 border-gray-800 text-gray-400 font-medium text-xs leading-tight uppercase rounded hover:bg-black hover:bg-opacity-5 focus:outline-none focus:ring-0 transition duration-150 ease-in-out"
    >
      Logout
    </button>
  )
}

export default Logout
