import API_CONSTANT from '../../config/Apiconstant'
import axiosInstance from '../../config/axios_custom'
import { Store } from 'react-notifications-component'

function Table({ linkList, getLinksData, setLinksList }) {
    const deleteLinkWithID = async (id) => {
        await axiosInstance.delete(API_CONSTANT.API_DELETE_LINK + `/${id}`)
        Store.addNotification({
            title: 'Done',
            message: 'Deleted Link',
            type: 'info',
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 2000,
                onScreen: true,
            },
        })

        getLinksData()
    }

    return (
        <section className="antialiased text-gray-600 md:w-3/4 w-11/12">
            <div className="flex flex-col justify-center">
                <div className="bg-white shadow-lg rounded-sm border border-gray-200">
                    <header className="px-5 py-4 border-b border-gray-100">
                        <h2 className="font-semibold text-gray-800 text-left">
                            Generated Links
                        </h2>
                    </header>
                    <div className="p-3">
                        <div className="overflow-x-auto">
                            <table className="table-auto w-full">
                                <thead className="text-xs font-semibold uppercase text-gray-400 bg-gray-50">
                                    <tr>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-left">Id</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-left">
                                                Android Link
                                            </div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-left">IOS Link</div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-left">
                                                Generated Link
                                            </div>
                                        </th>
                                        <th className="p-2 whitespace-nowrap">
                                            <div className="font-semibold text-center">Action</div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="text-sm divide-y divide-gray-100">
                                    {(linkList || []).map((link, index) => {
                                        return (
                                            <tr key={index} className="max-w-xs w-full">
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="flex items-center">
                                                        <div className="font-medium text-gray-800">
                                                            {index + 1}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="text-left w-60 truncate ...">
                                                        {link['androidLink']}
                                                    </div>
                                                </td>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="text-left w-60 truncate ...">
                                                        {link['iosLink']}
                                                    </div>
                                                </td>
                                                <td className="p-2 whitespace-nowrap pt-4">
                                                    <div className="text-left w-60 flex">
                                                        <img
                                                            src={!link['copied'] ? "/copy.png" : '/done.png'}
                                                            alt="copy to clipboard button"
                                                            height={20}
                                                            width={20}
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                const linkListUpdate = [...linkList]
                                                                linkListUpdate[index].copied = true
                                                                setLinksList([...linkListUpdate])
                                                                navigator.clipboard.writeText(
                                                                    API_CONSTANT.OUR_BASE_URL + link['Id'],
                                                                )
                                                            }}
                                                        ></img>
                                                        <div className="text-left pl-2 text-green-500 truncate ...">
                                                            {API_CONSTANT.OUR_BASE_URL + link['Id']}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td className="p-2 whitespace-nowrap">
                                                    <div className="flex space-x-2 justify-center">
                                                        <div>
                                                            <button
                                                                type="button"
                                                                onClick={() => {
                                                                    // eslint-disable-next-line no-restricted-globals
                                                                    const answer = confirm(
                                                                        'Are you sure you want to delete this?',
                                                                    )
                                                                    if (answer) {
                                                                        deleteLinkWithID(link['Id'])
                                                                    }
                                                                }}
                                                                className="inline-block rounded-full bg-red-400 text-white leading-normal uppercase shadow-md hover:bg-red-700 hover:shadow-lg focus:bg-red-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-red-800 active:shadow-lg transition duration-150 ease-in-out w-9 h-9"
                                                            >
                                                                X
                                                            </button>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Table
